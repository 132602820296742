import React from "react";
import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import MainLayout from "../components/layout/main-layout";
import { Header, HeaderImage } from "../components/layout/header";
import MushroomList from "../components/products/mushroom-list";
import MushroomsDescription from "../components/products/mushrooms-description";
import BackgroundImage from "../images/fundo-produtos.jpg";

const ProdutosPage = () => (
  <Layout>
    <SEO
      title="Produtos"
      keywords={["produtos", "veggie"]}
      image={BackgroundImage}
    />

    <HeaderImage image={BackgroundImage}>
      <MainLayout size="100vh">
        <Header
          title="Os nossos produtos…"
          subtitle="Os melhores e mais saborosos Cogumelos de Argoncilhe."
        />

        <MushroomList />
      </MainLayout>
    </HeaderImage>

    <MushroomsDescription />
  </Layout>
);

export default ProdutosPage;
