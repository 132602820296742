import React from "react";
import styled from "@emotion/styled";
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

const MushroomListContainer = styled.div`
  padding: 20px;
  transform: translateZ(0px);
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
  }

  @media screen and (min-width: 1400px) {
    padding: 80px 0;
  }
`;

const List = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
`;

const Shitake = styled(GatsbyImage)`
  width: 200px;
  height: 200px;
  filter: drop-shadow(10px 10px 10px #000);

  @media screen and (min-width: 1200px) {
    width: 260px;
    height: 270px;
  }
`;

const Pleurotus = styled(GatsbyImage)`
  width: 300px;
  height: 200px;
  filter: drop-shadow(10px 10px 10px #000);

  @media screen and (min-width: 1200px) {
    width: 380px;
    height: 270px;
  }
`;

const Desidratados = styled(GatsbyImage)`
  width: 200px;
  height: 200px;
  filter: drop-shadow(10px 10px 10px #000);

  @media screen and (min-width: 1200px) {
    width: 260px;
    height: 270px;
  }
`;

const ItemTitle = styled.p`
  font-family: "DM Sans", sans-serif;
  letter-spacing: 0.71px;
  margin: 14px 0;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  text-transform: capitalize;
`;

const scrollInto = (id) => {
  const element = document.getElementById(id);

  if (element) {
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }
};

const MushroomList = () => {
  const { shitake, pleurotus, desidratados } = useStaticQuery(graphql`{
  shitake: file(relativePath: {eq: "shitake.png"}) {
    childImageSharp {
      gatsbyImageData(
        width: 270
        height: 270
        quality: 100
        placeholder: BLURRED
      )
    }
  }
  pleurotus: file(relativePath: {eq: "pleurotus.png"}) {
    childImageSharp {
      gatsbyImageData(
        width: 365
        height: 270
        quality: 100
        placeholder: BLURRED
      )
    }
  }
  desidratados: file(relativePath: {eq: "desidratados.png"}) {
    childImageSharp {
      gatsbyImageData(
        width: 270
        height: 270
        quality: 100
        placeholder: BLURRED
      )
    }
  }
}
`);

  return (
    <MushroomListContainer>
      <List>
        <Item onClick={() => scrollInto("shitake")}>
          <Shitake
            image={shitake.childImageSharp.gatsbyImageData }
            alt="Shitake"
          />
          <ItemTitle>Shitake</ItemTitle>
        </Item>
        <Item onClick={() => scrollInto("pleurotus")}>
          <Pleurotus
            image={pleurotus.childImageSharp.gatsbyImageData }
            alt="Pleurotus"
          />
          <ItemTitle>Pleurotus</ItemTitle>
        </Item>
        <Item onClick={() => scrollInto("desidratados")}>
          <Desidratados
            image={desidratados.childImageSharp.gatsbyImageData }
            alt="Desidratados"
          />
          <ItemTitle>Desidratados</ItemTitle>
        </Item>
      </List>
    </MushroomListContainer>
  );
};

export default MushroomList;
