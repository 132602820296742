import React from "react";
import styled from "@emotion/styled";
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import MainLayout from "../layout/main-layout";
import BenefitsShitake1 from "../../images/benefits-shitake-1.png";
import BenefitsShitake2 from "../../images/benefits-shitake-2.png";

const MushroomsDescriptionContainer = styled.div`
  background-color: var(--color3);
  padding: 20px;

  @media screen and (min-width: 768px) {
    padding: 80px;
  }

  @media screen and (min-width: 1400px) {
    padding: 80px 0;
  }
`;

const MushroomContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  margin-bottom: 60px;

  @media screen and (min-width: 1024px) {
    grid-template-columns: 60% 40%;
  }
`;

const Title = styled.h2`
  font-family: "DM Sans", sans-serif;
  color: var(--color2);
  font-size: 40px;
  margin: 0;
`;

const Description = styled.p`
  color: var(--color2);
  font-size: 19px;
`;

const Right = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (min-width: 1024px) {
    justify-content: flex-end;
  }
`;

const ItemImage = styled(GatsbyImage)`
  width: 270px;
  min-height: 270px;
  border-radius: 10px;
`;

const ShitakeCycleContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  margin-bottom: 60px;

  @media screen and (min-width: 1024px) {
    grid-template-columns: 46% 46%;
    grid-gap: 8%;
  }
`;

const ShitakeCycleImg = styled.img`
  width: 100%;
`;

const MushroomsDescription = () => {
  const { shitake, pleurotus, desidratados } = useStaticQuery(graphql`
    {
      shitake: file(relativePath: { eq: "shitake-description.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 270, height: 270, quality: 100)
        }
      }
      pleurotus: file(relativePath: { eq: "pleurotus-description.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 270, height: 270, quality: 100)
        }
      }
      desidratados: file(relativePath: { eq: "desidratados-description.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 270, height: 270, quality: 100)
        }
      }
    }
  `);

  return (
    <MushroomsDescriptionContainer>
      <MainLayout>
        <MushroomContainer id="shitake">
          <div>
            <Title>Shitake</Title>
            <Description>
              <b>
                (Lentinula edodes) / substância ativa que combate as
                proliferação
              </b>
              <br />
              Original do Japão, é a segunda variedade mais consumida no mundo,
              atualmente. As propriedades do Shiitake fazem deste um cogumelo
              Premium: possui elevado teor de aminoácidos, hidratos de carbono,
              vitaminas e proteínas e um baixíssimo valor calórico.{" "}
              <b>O preferido das dietas Veggie!</b>
            </Description>
          </div>
          <Right>
            <ItemImage
              image={shitake.childImageSharp.gatsbyImageData}
              alt="Shitake"
            />
          </Right>
        </MushroomContainer>
        <ShitakeCycleContainer>
          <ShitakeCycleImg src={BenefitsShitake1} alt="Ciclo Shitake" />
          <ShitakeCycleImg src={BenefitsShitake2} alt="Ciclo Shitake" />
        </ShitakeCycleContainer>
        <MushroomContainer id="pleurotus">
          <div>
            <Title>Pleurotus</Title>
            <Description>
              O cogumelo Pleurotus é um dos cogumelos comestíveis mais saudáveis
              para incluir na alimentação.
              <br /> Conhecido por seu impressionante perfil nutricional,são
              ricos em proteínas de origem não animal, sendo uma alternativa
              especialmente saudável para pessoas que seguem dietas
              vegetarianas.
              <br /> Os cogumelos Pleurotus fornecem alto teor de fibras.
            </Description>
          </div>
          <Right>
            <ItemImage
              image={pleurotus.childImageSharp.gatsbyImageData}
              alt="Pleurotus"
            />
          </Right>
        </MushroomContainer>
        <MushroomContainer id="desidratados">
          <div>
            <Title>Desidratados</Title>
            <Description>
              Nos cogumelos desidratados, conseguimos preservar todos os seus
              componentes intactos até ao momento da sua elaboração. <br />
              Após desidratação, torna-se mais fácil a sua conservação durante
              um período longo. Deste modo são fáceis de transportar sem
              necessidade de colocar no frio.
            </Description>
          </div>
          <Right>
            <ItemImage
              image={desidratados.childImageSharp.gatsbyImageData}
              alt="Desidratados"
            />
          </Right>
        </MushroomContainer>
      </MainLayout>
    </MushroomsDescriptionContainer>
  );
};

export default MushroomsDescription;
